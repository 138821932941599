@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --view-height-25:25dvh;
  --view-height-50:50dvh;
  --view-height-75:75dvh;
  --view-height-100:100dvh;
  --color-white: #fafafa;
  --color-darkerwhite: #f9f9f9;
  --color-green:#5cb85c;
  --color-grey:#555;
  --color-primary-color: #212529;
  --color-secondary-color:#272b2d;
  --color-lighter-primary:#515f69;
  --font-size-12:.85rem;
  --font-size-13:1rem;
  --font-size-14:1.5rme;
  --font-size-10:.5rem;
  --font-size-8:.3rem;
  --color-pet:#FB6E6E;

}
.theme-color{
  background-color: var(--color-primary-color);
}
.theme-color-light{
  background-color: var(--color-secondary-color);
}
.theme-color-lighter{
  background-color: var(--color-lighter-primary);
}
.c-red{
  color: #ff2f4b;
}
.pet-img{
  width: 4rem;
  height: 4rem;
  border-radius: 5px;
  object-fit: cover;
}
.btn-bg{
  background-color: var(--color-pet);
  color: var(--color-white);
}
.c-pet{
  color: var(--color-pet);
}
.text-inactive{
  color: #f4eeec;
}
hr{
  color: var(--color-darkerwhite);
}
.border-none{
  border: none;
}
body{
  padding: 0;
  margin: 0;
  font-family: 'poppins', sans-serif;
  color: var(--color-primary-color);
  background: var(--color-secondary-color);
}
.circle{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--lighter-white);
}
header{
  background-color: var(--color-white);
}
.badge-sm{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-lighter-primary);
}
.badge-md{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--color-lighter-primary);
}
.badge-lg{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: var(--color-lighter-primary);
}
.badge-xlg{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--lighter-white);
}
.bg-white{
  background-color: var(--color-white);
}
.img-area{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid var(--color-green);
}
.fs-12{
  font-size: var(--font-size-12);
}
.fs-13{
  font-size: var(--font-size-13);
}
.fs-14{
  font-size: var(--font-size-14);
}
.fs-10{
  font-size: var(--font-size-10);
}
.fs-8{
  font-size: var(--font-size-8);
}
.active-badge{
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-green);
  color: var(--color-green);
  z-index: 1000;
  position: relative;
  inset: 20px 18px;
}
.pb-xl{
  padding-bottom: 100rem;
}
.pie-chart{
  height: 20rem;
}
.line-chart{
  width: 50rem;
}
.piechart-two{
  height: 25rem;
}
.icon-badge{
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
  background: var(--color-primary-color);
  color: var(--color-green);
  margin-right: 10px;
  margin-bottom: 2px;
}
.top-badge{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--color-lighter-primary);
}
header{
  box-sizing: border-box; 
}
.search-input, :focus{
   outline: none;
}
.profile-image{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 3px dashed var(--color-green);
}
.link{
  text-decoration: none;
}
.noti-pill{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-green);
  z-index: 1000;
  position: relative;
  left: -2px;
  top: -10px;
}
.profile-pic-sm{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.icon-color{
  color: var(--color-secondary-color);
}
.color-grey{
  color: var(--color-grey);
}
.outline-none{
  outline: none;
}
.bg-darkwhite{
  background-color: var(--lighter-white);
}
.link{
  text-decoration: none;
  width: 100%;
}
.bg-dark-white{
  background-color: var(--lighter-white);
}
.logo{
  width: 50px;
  height: 50px;
  background-color: var(--color-secondary-color);
}
.app-logo{
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.graph-box{
  height: 17rem;
}
.logo-app{
  width: 100%;
  height: 2rem;
}
.btn-active{
   width: 6rem;
   height: 1.8rem;
   border: none;
   font-size: 0.8em;
   border-radius: 20px;
   font-weight: 500;
   background-color: var(--color-green);
   color: var(--lighter-white);
}
.btn-inactive{
  width: 6rem;
  height: 1.8rem;
  border: none;
  font-size: 0.8em;
  border-radius: 20px;
  font-weight: 500;
  background-color: #FFC000;
}
.takeaction-btn{
  width: 7rem;
  height: 1.9rem;
  border-radius: 8px;
  border:none;
  font-size: 0.8em;
  font-weight: 500;
}
.card-img{
  height: 10em;
  object-fit: cover;
}
.graph-box-two{
  height: 23rem;
}
.profile-box{
  height: 8rem;
  width: 8rem;
  border: 5px solid var(--color-green);
  border-radius: 50%;
}
.table-scrollable{
  max-height: 70dvh;
  height: auto;
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.table-scrollable::-webkit-scrollbar{
  width: .3rem;
}
.table-scrollable::-webkit-scrollbar-thumb{
  background-color: var(--color-pet);
  outline: none;
  border-radius: 20px;
}
.select{
  border: none;
  width: 10rem;
}
.icon-badge-nav{
    width: 2.5em;
    height: 2.5em;
    background-color: var(--color-secondary-color);
    border-radius: 50%;
}
.search{
  border: none;
  background-color: transparent;
  border-bottom: 1px solid var(--color-lighter-primary);
}
.login-container{
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar{
    transition: all 0.5s;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    max-width: 16em;
    min-width: 16em;
    background: var(--color-primary-color);
}
.sidebar-two{
  transition: all 0.5s;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  max-width: 16em;
  min-width: 16em;
  background: var(--color-primary-color);
}
.noti-badge{
  width: 2rem;
  height: 2rem;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary-color);
  border-radius: 50%;
  position: relative;
}
.noti{
  border: none;
  border-radius: 50%;
  background: var(--color-green);
  font-weight: 500;
  position: relative;
  bottom: 0;
  left: -4px;
  font-size: var(--font-size-12);
  color: var(--color-darkerwhite);
  z-index: 3000;
}
.link-active{
  border-left: 4px solid var(--color-pet) !important;
  color: var(--color-pet) !important;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.link{
  text-decoration: none;
  color: var(--color-grey);
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.link:hover{
  color: var(--color-white);
  transition: all 0.4s;
}
.main{
  height: 100dvh;
  transition: all 0.3s;
  background-color: var(--color-secondary-color);
}
.profile{
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: var(--color-secondary-color);
}
.profile-img{
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
    .dis-none-lg{
       display: none;
    }
}

@media screen and (max-width: 767px) {
   .sidebar{
      opacity: 0;
      display: none !important;
      transition: 0.4s allow-discrete;
   }
}